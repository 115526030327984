import React from "react";
import "./css/Homepage.css";

import emily from "./img/emily.jpg";
import arfa from "./img/ARFALogoIcon.svg";
import cookie from "./img/cookie.jpg";
import acro from "./img/acro.jpg";
import useDocumentTitle from "./hooks/useDocumentTitle";
import useMediaQuery from "./hooks/useMediaQuery";

const Homepage = () => {
  useDocumentTitle("UX Designer");

  return (
    <div className="app container">
      <Banner />
      <RecentWork />
      <Studio />
    </div>
  );
};

const Banner = () => {
  const isMobile = useMediaQuery(`(max-width: 800px)`);
  const bgImage = isMobile ? `url('${emily}')` : "";

  return (
    <div className="banner row" style={{ backgroundImage: bgImage }}>
      <div className="welcome col">
        <h1>Hi, I'm Emily.</h1>
        <p>I improve the human experience by creating intuitive design.</p>
        <hr />
      </div>
      {!isMobile && (
        <div className="col">
          <img src={emily} alt="Emily Temple" />
        </div>
      )}
    </div>
  );
};

const RecentWork = () => {
  return (
    <div className="row">
      <h2 className="dot pinkDot d-block mb-4">My Recent Work</h2>
      <div className="project container p-5">
        <div className="row">
          <div className="col-auto">
            <img
              src={arfa}
              alt="Augmented Reality Flow Arts Logo"
              width="300"
            />
          </div>
          <div className="col information text-left gy-3">
            <div className="role row">Lead Research and Designer for</div>
            <div className="company row">Augmented Reality Flow Arts</div>
            <p className="row">
              Flow artists can animate flow object(s) in their video recording
              using the ARFlowArts mobile application.
            </p>
            <p className="row">
              ARFlowArts offers a selection of free animations to artists, with
              the option to purchase additional animations for a wider range of
              video effects.
            </p>
            <div className="row text-right read-more">
              <a href="/#">Read More...</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Studio = () => {
  return (
    <div className="row py-5">
      <h2 className="dot blueDot mb-4">Studio</h2>
      <div className="row p-0">
        <StudioBlock
          title="AcroShtuff"
          desc="I do this stuff and yeah"
          url="/acro"
          image={acro}
        />
        <StudioBlock
          title="Cookie Monster"
          desc="I do this stuff and yeah"
          url="/cookies"
          image={cookie}
        />
        <StudioBlock
          title="Acro"
          desc="I do this stuff and yeah"
          url="/acro"
          image={acro}
        />
      </div>
    </div>
  );
};

const StudioBlock = ({ title, desc, url, image }) => {
  return (
    <div className="col p-4 studio-project d-block">
      <img src={image} alt={desc} width="100%" />
      <p className="studio-project-title mt-3 mb-0">{title}</p>
      <p className="studio-project-desc">{desc}</p>
      <a className="studio-project-link text-center d-block" href={url}>
        View
      </a>
    </div>
  );
};

export default Homepage;
