import "bootstrap/dist/css/bootstrap.css";

import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import Homepage from "./Homepage";
import ProjectPage from "./ProjectPage";
import Header from "./Header";
import Footer from "./Footer";
import "./css/App.css";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/projects" component={ProjectPage} />
        <Route path="/studio" component={StudioPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/" component={Homepage} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

const AboutPage = () => <h1>About</h1>;
const ContactPage = () => <h1>Contact</h1>;
const StudioPage = () => <h1>Studio</h1>;

export const MOBILE_WIDTH = "1100px";

export default App;
