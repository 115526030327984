import React from "react";
import StickyBox from "react-sticky-box";
import "./css/ProjectPage.css";
import useDocumentTitle from "./hooks/useDocumentTitle";

import photo from "./img/portfolio-headshot.jpg";
// import poopoo from "./img/poo.png";
import confusing from "./img/confusing.png";
import capabilities from "./img/capabilities.png";
import unamused from "./img/unamused.png";
import woozy from "./img/woozy.png";
import perservere from "./img/perservere.png";

const ProjectPage = () => {
  useDocumentTitle("Projects");

  return (
    <>
      <Banner />
      <div className="container-fluid project-holder">
        <div className="sidebar-holder">
          <Sidebar />
        </div>
        <div className="project-contents">
          <TheProject />
          <TheCompany />
          <MyRole />
          <div class="story-block alt">
            <div class="story-block-title">the problem</div>
            <div className="row p-5 problems justify-content-center">
              <div className="problem col-sm-4">
                <img src={woozy} alt="Problem Diagram" />
                <h5>Poor Interaction Design</h5>
                <p>
                  Slowed down the video editing process and hindered precision
                </p>
              </div>
              <div className="problem col-sm-4">
                <img src={confusing} alt="Problem Diagram" />
                <h5>Confusing Features</h5>
                <p>
                  ARFlowArts produced how-to videos on Instagram or directly
                  helped users via instant messenger.
                </p>
              </div>
              <div className="problem col-sm-4">
                <img src={capabilities} alt="Problem Diagram" />
                <h5>Limited Capabilities</h5>
                <p>Users supplemented with 3rd party applications.</p>
              </div>
              <div className="problem col-sm-4">
                <img src={unamused} alt="Problem Diagram" />
                <h5>Poor Usability of Existing Features</h5>
                <p>Decreased in-app purchases.</p>
              </div>
              <div className="problem col-sm-4">
                <img src={perservere} alt="Problem Diagram" />
                <h5>Poor Usability of Existing Features</h5>
                <p>Prevented Addition of new purchase options.</p>
              </div>
            </div>
          </div>
          <div class="story-block blue">
            <div class="story-block-title">understanding the user</div>
          </div>
          <div class="story-block">
            <div class="story-block-title">the project</div>
          </div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
          <div class="story-block"></div>
        </div>
      </div>
    </>
  );
};

export default ProjectPage;

const Banner = () => (
  <div className="container-fluid project-banner py-5">
    <div className="app container">
      <h1>Augmented Reality Flow Arts</h1>
      <h3>Lead Designer & Lead Researcher</h3>
      <h4>Android & iOS App Redesign</h4>
    </div>
  </div>
);

const TheProject = () => (
  <div class="story-block">
    <div class="story-block-title">the project</div>
    <div class="row project-details p-5">
      <div class="col-sm">
        <span class="project-details-header">Team</span>
        <ul>
          <li>Myself</li>
          <li>2 Developers</li>
        </ul>
      </div>
      <div class="col-sm">
        <span class="project-details-header">Tools</span>
        <ul>
          <li>Interface Design</li>
          <li>Information Architecture</li>
          <li>User Research</li>
          <li>Interaction Design</li>
        </ul>
      </div>
      <div class="col-sm">
        <span class="project-details-header">Tools</span>
        <ul>
          <li>Adobe XD</li>
          <li>Miro</li>
          <li>Figma</li>
        </ul>
      </div>
      <div class="col-sm">
        <span class="project-details-header">Project Type</span>
        <ul>
          <li>Mobile: iOS & Android</li>
        </ul>
      </div>
    </div>
  </div>
);

const TheCompany = () => (
  <div className="the-company">
    <h4>
      <span>The Company</span> VIDEO EDITING FOR FLOW ARTISTS
    </h4>
    <br />
    <h3>What is a Flow Artist?</h3>
    <p>
      A person who combines object manipulation and movement into a sequence or
      dance. Juggling and hula hooping are common examples of flow arts. Flow
      artists can animate flow object(s) in their video recording using the
      ARFlowArts mobile application.
    </p>
    <br />
    <h3>What is ARFlowArts?</h3>
    <p>
      ARFlowArts offers a selection of free animations to artists, with the
      option to purchase additional animations for a wider range of video
      effects.
    </p>
  </div>
);

const MyRole = () => (
  <div class="story-block blue my-role">
    <div class="story-block-title">my role</div>
    <div class="row p-4">
      <div className="col-md-3">
        <img src={photo} alt="Emily Temple, UX Designer" />
      </div>
      <div
        className="col flex flex-column justify-content-center align-content-center"
        style={{ display: "flex" }}
      >
        <p>
          “My role was to redesign the mobile app so users can easily create
          animated videos. By improving usability, I increased the demand for
          new animations, which boosted in app purchases and app usage.”
        </p>
      </div>
    </div>
  </div>
);

const Sidebar = () => {
  return (
    <StickyBox>
      <div class="project-sidebar">
        <span class="project-sidebar-header">Contents</span>
        <ul>
          <li>
            <a href="/#">Project Brief</a>
          </li>
          <li>
            <a href="/#">Objectives</a>
          </li>
          <li>
            <a href="/#">Problem</a>
          </li>
          <li>
            <a href="/#">User Persona</a>
          </li>
          <li>
            <a href="/#">Ideation</a>
          </li>
          <li>
            <a href="/#">Wire Framing</a>
          </li>
          <li>
            <a href="/#">Initial Mockup</a>
          </li>
          <li>
            <a href="/#">User Testing</a>
          </li>
          <li>
            <a href="/#">MVP</a>
          </li>
          <li>
            <a href="/#">Design Release</a>
          </li>
          <li>
            <a href="/#">Lessons Learned</a>
          </li>
        </ul>
      </div>
    </StickyBox>
  );
};
