import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MOBILE_WIDTH } from "./App";
import useMediaQuery from "./hooks/useMediaQuery";
import strawberry from "./img/strawberry.png";

const Header = () => {
  return (
    <div className="header-wrapper">
      <header className="header container">
        <div className="logo col container">
          <Link to="/">
            <div>
              <img
                src={strawberry}
                alt="Strawberry logo"
                width="69px"
                height="74px"
              />
              <span>Emily Temple</span>
            </div>
          </Link>
        </div>
        <Navigation />
      </header>
    </div>
  );
};

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_WIDTH})`);

  return (
    <>
      {isMobile && (
        <button
          type="button"
          className="nav-btn menu-btn"
          onClick={() => setOpen(!open)}
        >
          <span className="sr-only">Open Menu</span>
          <span className="ham"></span>
          <span className="ham"></span>
          <span className="ham"></span>
        </button>
      )}
      {(open || !isMobile) && (
        <ul className="navigation">
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/studio">Studio</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      )}
    </>
  );
};

export default Header;
export { Navigation };
