import React from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./Header";
import whiteStrawberry from "./img/strawberry-whiteout.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-center container">
        <div className="row">
          <div className="col py-4 footer-logo">
            <Link to="/">
              <img src={whiteStrawberry} alt="Strawberry" />
              <span className="p-3">Emily Temple</span>
            </Link>
          </div>
          <Navigation />
        </div>
      </div>
    </div>
  );
};
export default Footer;
